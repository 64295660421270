

























































// library
import Vue, { VueConstructor } from 'vue';
// types
import { FormWrapperRequest } from '@/types/form';
// data
import industries from '@/data/industries.json';
import formSubmit from '@/mixins/formSubmit';
// components
import ApiError from '@/components/ApiError.vue';
import FieldLink from '@/components/fields/FieldLink.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import EditInfoBanner from '@/components/profile/edit/EditInfoBanner.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProfileEditWork',
  components: {
    EditBtn,
    EditInfoBanner,
    AppModal,
    ApiError,
    FieldLink,
    FieldLabel,
    Btn,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'put',
          url: '/user/work',
        },
      };
    },
  },
  data() {
    return {
      model: {},
      disableSubmit: false,
      industries,
    };
  },
  methods: {
    open() {
      const details = this.$store.getters['profileModule/work'];

      this.model = {
        industry: details.industry,
        role: details.role,
        companyWebsite: details.companyWebsite,
      };

      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      this.dispatch('profileModule/loadWorkDetails', true);
      this.notify('Work details updated');
      (this.$refs.modal as any).close();
    },
  },
});
