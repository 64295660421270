























































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import Btn from '@/components/buttons/Btn.vue';
import EditInfoBanner from '@/components/profile/edit/EditInfoBanner.vue';
import AppModal from '@/components/AppModal.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProfileEditUser',
  components: {
    EditBtn,
    AppModal,
    EditInfoBanner,
    Btn,
    ApiError,
    FieldDescription,
    FieldLabel,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'put',
          url: '/user/account',
        },
      };
    },
  },
  data() {
    return {
      model: {},
    };
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }
      this.dispatch('profileModule/loadUserDetails', true);
      this.notify('User details updated');
      (this.$refs.modal as any).close();
    },
  },
  created() {
    const user = this.$store.getters['profileModule/user'];

    this.model = {
      forename: user.forename,
      surname: user.surname,
      displayName: user.displayName,
      bio: user.bio,
    };
  },
});
