
















































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import FieldPassword from '@/components/fields/FieldPassword.vue';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProfileEditPassword',
  components: {
    AppModal,
    Btn,
    ApiError,
    FieldPassword,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: { password: this.model.newPassword },
          method: 'put',
          url: '/user/password',
        },
      };
    },
  },
  data() {
    return {
      model: {
        newPassword: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      this.notify('Password updated. Please login using your new password');
      await this.$router.push('/logout');
    },
  },
});
