




















































































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { formatDate, getTimestampFromDateString } from '@/util/date';
// types
import { PersonalDetails } from '@/types/profile';
import { FormWrapperRequest } from '@/types/form';
// data
import countries from '@/data/countries.json';
// components
import ApiError from '@/components/ApiError.vue';
import FieldDate from '@/components/fields/FieldDate.vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import EditInfoBanner from '@/components/profile/edit/EditInfoBanner.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProfileEditPersonal',
  components: {
    EditBtn,
    EditInfoBanner,
    AppModal,
    Btn,
    ApiError,
    FieldDate,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      const data = { ...this.model } as Partial<PersonalDetails>;

      if (data.dateOfBirth && typeof data.dateOfBirth === 'string') {
        data.dateOfBirth = getTimestampFromDateString(data.dateOfBirth);
      }

      if (!data.dateOfBirth) {
        data.dateOfBirth = 0;
      }

      return {
        config: {
          data,
          method: 'put',
          url: '/user/profile',
        },
      };
    },
  },
  data() {
    return {
      model: {
        dateOfBirth: 0,
        gender: '',
        nationality: '',
        residency: '',
      },
      countries,
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
        { label: 'Other', value: 'O' },
        { label: 'Prefer not to say', value: 'N' },
      ],
    };
  },
  methods: {
    open() {
      const details = this.$store.getters['profileModule/personal'];
      let { dateOfBirth } = details;
      if (!dateOfBirth) {
        dateOfBirth = '';
      } else {
        dateOfBirth = formatDate(dateOfBirth) || null;
      }

      this.model = {
        dateOfBirth,
        gender: details.gender,
        nationality: details.nationality,
        residency: details.residency,
      };
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      this.dispatch('profileModule/loadPersonalDetails', true);
      this.notify('Personal details updated');
      (this.$refs.modal as any).close();
    },
  },
});
