

































import Vue from 'vue';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import { formatDate } from '@/util/date';
import { UserDetails } from '@/types/profile';

export default Vue.extend({
  name: 'ProfileUser',
  components: {
    DetailsCardRow,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user(): Partial<UserDetails> {
      return this.$store.getters['profileModule/user'];
    },
    createdAt(): string {
      return formatDate(this.user.createdAt || 0);
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('profileModule/loadUserDetails');
    await this.$store.dispatch('profileModule/loadSocialDetails');
    this.loading = false;
  },
  methods: {
    async forceReload() {
      await this.$store.dispatch('profileModule/loadUserDetails', true);
    },
  },
});
