




























































import Vue from 'vue';
import Page from '@/layouts/Page.vue';
import ProfileWork from '@/components/profile/ProfileWork.vue';
import ProfileUser from '@/components/profile/ProfileUser.vue';
import ProfilePersonal from '@/components/profile/ProfilePersonal.vue';
import AppCard from '@/components/card/AppCard.vue';
import ProfileEditPassword from '@/components/profile/edit/ProfileEditPassword.vue';
import ProfileEditPersonal from '@/components/profile/edit/ProfileEditPersonal.vue';
import ProfileEditWork from '@/components/profile/edit/ProfileEditWork.vue';
import ProfileEditUser from '@/components/profile/edit/ProfileEditUser.vue';
import AvatarWithControls from '@/components/avatar/AvatarWithControls.vue';
import ViewSocial from '@/components/social/ViewSocial.vue';
import { SocialDetailsMap } from '@/types/social';

export default Vue.extend({
  components: {
    ViewSocial,
    AvatarWithControls,
    ProfileEditUser,
    ProfileEditWork,
    ProfileEditPersonal,
    ProfileEditPassword,
    Page,
    AppCard,
    ProfileUser,
    ProfileWork,
    ProfilePersonal,
  },
  computed: {
    name(): string {
      const details = this.$store.getters['profileModule/user'];
      return details.displayName || `${details.forename} ${details.surname}`;
    },
    avatarDetails(): { avatar: string; label: string; } {
      return {
        avatar: this.$store.getters['profileModule/avatar'],
        label: this.name,
      };
    },
    socialDetails(): SocialDetailsMap {
      return this.$store.getters['profileModule/social'];
    },
  },
  methods: {
    async loadUserDetails() {
      await this.$store.dispatch('profileModule/loadUserDetails', true);
    },
    async loadSocialDetails() {
      await this.$store.dispatch('profileModule/loadSocialDetails', true);
    },
  },
});
