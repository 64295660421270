





















import Vue from 'vue';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import { formatDate } from '@/util/date';
import countries from '@/data/countries.json';
import { PersonalDetails } from '@/types/profile';

export default Vue.extend({
  name: 'ProfilePersonal',
  components: {
    DetailsCardRow,
  },
  computed: {
    // nationality(): string {
    //   const country = countries.filter((c) => c.alpha_2_code === this.details.nationality)[0];
    //   if (!country) {
    //     return '';
    //   }
    //   return country.nationality;
    // },
    residency(): string {
      const country = countries.filter((c) => c.alpha_2_code === this.details.residency)[0];
      if (!country) {
        return '';
      }
      return country.en_short_name;
    },
    gender(): string {
      switch (this.details.gender) {
        case 'M':
          return 'Male';
        case 'F':
          return 'Female';
        case 'O':
          return 'Other';
        case 'N':
          return 'Prefer not to say';
        default:
          return '';
      }
    },
    dateOfBirth(): string {
      const { dateOfBirth } = this.details;
      if (!dateOfBirth) {
        return '';
      }
      return formatDate(this.details.dateOfBirth as number);
    },
    details(): Partial<PersonalDetails> {
      return this.$store.getters['profileModule/personal'];
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('profileModule/loadPersonalDetails');
    this.loading = false;
  },
  methods: {
    async forceReload() {
      await this.$store.dispatch('profileModule/loadPersonalDetails', true);
    },
  },
});
