





















import Vue from 'vue';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import { WorkDetails } from '@/types/profile';

export default Vue.extend({
  name: 'ProfileWork',
  components: {
    DetailsCardRow,
  },
  props: {
    hideEdit: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    details(): Partial<WorkDetails> {
      return this.$store.getters['profileModule/work'];
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('profileModule/loadWorkDetails');
    this.loading = false;
  },
  methods: {
    async forceReload() {
      await this.$store.dispatch('profileModule/loadWorkDetails', true);
    },
  },
});
